"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vuex_1 = require("vuex");
var data_setting_apis_1 = require("@/apis/data-setting.apis");
var common_utils_1 = require("@/common/utils/common.utils");
var table_vue_1 = require("./table.vue");
var docsUrl_1 = require("@common/configs/docsUrl");
var permission_1 = require("@/common/utils/permission");
var permissions_config_1 = require("@/common/configs/permissions.config");
var singleSpa_service_1 = require("@/common/services/singleSpa.service");
exports.default = {
    name: 'PropertySettings',
    data: function () {
        var userPropertyUploadPermision = permission_1.default.includePermissionWithStore([
            permissions_config_1.default.userPropertyUpload
        ]);
        var moreOptions = [
            {
                name: this.$t('common.button_edit'),
                code: 'edit',
                roleKey: 'ptxUserPropertyUpdate'
            }
        ].filter(function (item) { return (0, singleSpa_service_1.checkModuleRole)(item.roleKey); });
        var tableHeader = [
            {
                code: 'name',
                sortCode: 'showName',
                name: this.$t('data_settings.list_name'),
                dataType: 'string',
                supportSortable: true,
                span: 8
            },
            {
                code: 'source',
                sortCode: 'showSource',
                name: this.$t('data_settings.list_source'),
                tips: this.$t('annotation.data_2', { link: docsUrl_1.default.dataSetting }),
                dataType: 'string',
                supportSortable: true,
                span: 4
            },
            {
                code: 'type',
                sortCode: 'showType',
                name: this.$t('data_settings.list_type'),
                canEdit: true,
                dataType: 'string',
                supportSortable: true,
                span: 4
            },
            {
                code: 'description',
                sortCode: 'showDescription',
                name: this.$t('common.description'),
                canEdit: true,
                dataType: 'string',
                supportSortable: true,
                span: 8
            }
            // {
            //     code: 'users',
            //     name: 'Users with property',
            //     dataType: 'string',
            //     supportSortable: true,
            //     span: 6
            // }
        ];
        var typeOptions = [
            {
                code: 'STRING',
                name: this.$t('data_settings.data_type_string')
            },
            {
                code: 'TIME',
                name: this.$t('data_settings.data_type_date')
            },
            {
                code: 'NUMBER',
                name: this.$t('data_settings.data_type_number')
            }
        ];
        var sourceOptions = [
            {
                code: 'code',
                name: this.$t('data_settings.data_source_code')
            },
            {
                code: 'experience',
                name: this.$t('data_settings.data_experience')
            },
            {
                code: 'api',
                name: 'API'
            },
            {
                code: 'default',
                name: this.$t('data_settings.data_source_default')
            },
            {
                code: 'engage',
                name: this.$t('data_settings.data_source_engage')
            }
        ];
        return {
            moreOptions: moreOptions,
            tableHeader: tableHeader,
            sourceOptions: sourceOptions,
            typeOptions: typeOptions,
            tableBody: [],
            joinString: '_tmp',
            loading: true,
            docUrl: docsUrl_1.default.identifyUser,
            uploadUrl: '',
            extraData: null,
            uploadCsvDialogVisible: false,
            uploading: false,
            userPropertyUploadPermision: userPropertyUploadPermision
        };
    },
    created: function () {
        this.fetchUserProperties();
        this.userPropertyUploadPermision && this.preFetchS3SignedUrl();
    },
    computed: __assign(__assign({}, (0, vuex_1.mapGetters)('main', ['profileInfo'])), { canEditList: function () {
            return this.tableHeader.filter(function (item) { return item.canEdit; });
        }, currentRouteIsMe: function () {
            return this.$route.name === 'UserPropertySettings';
        } }),
    methods: {
        showUploadDialog: function () {
            this.uploadCsvDialogVisible = true;
        },
        onUploadSuccess: function (_, file) {
            var _this = this;
            this.uploading = false;
            var fileName = file.raw.name;
            data_setting_apis_1.default.createUserPropertiesSyncTask(null, {
                input: {
                    s3FileKey: this.extraData.key,
                    profileId: this.profileInfo.sid,
                    fileName: fileName
                }
            });
            this.uploadCsvDialogVisible = false;
            this.$alert(this.$t('data_settings.user_property_csv_upload_success_tips'), this.$t('common.title_confirm'), {
                confirmButtonText: this.$t('common.button_confirm'),
                callback: function (action) {
                    _this.$refs.csvUploadRef.clearFiles();
                    // 上传成功重新初始化上传配置
                    _this.preFetchS3SignedUrl();
                }
            });
        },
        onUploadError: function () {
            this.uploading = false;
            this.$message.error(this.$t('data_settings.user_property_csv_upload_error_tips'));
        },
        onBeforeUpload: function (file) {
            return __awaiter(this, void 0, void 0, function () {
                var partOfContent, indexOfNextLine, firstLine, isLt200M, confirmResult;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // 截取长度1024
                            // 仅支持导入csv格式的文件 Only .csv files are allowed
                            if (file.type && file.type.toLowerCase() !== 'text/csv') {
                                this.$message.error(this.$t('data_settings.user_property_csv_upload_format_check_tips'));
                                return [2 /*return*/, Promise.reject()];
                            }
                            return [4 /*yield*/, file.slice(0, 1024).text()];
                        case 1:
                            partOfContent = _a.sent();
                            indexOfNextLine = partOfContent.indexOf('\n');
                            firstLine = partOfContent.substring(0, indexOfNextLine + 1);
                            isLt200M = file.size / 1024 / 1024 < 200;
                            if (!isLt200M) {
                                this.$message.error(this.$t('data_settings.user_property_csv_upload_exceed200M_tips'));
                                return [2 /*return*/, Promise.reject()];
                            }
                            if (!(firstLine.toLowerCase().indexOf('user_id') === -1)) return [3 /*break*/, 3];
                            return [4 /*yield*/, this.$confirm(this.$t('data_settings.user_property_csv_upload_invalid_confirm'), this.$t('common.title_confirm'), {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: this.$t('common.button_confirm'),
                                    cancelButtonText: this.$t('common.button_cancel'),
                                    cancelButtonClass: 'user-property-setting_cancel_tips'
                                })];
                        case 2:
                            confirmResult = _a.sent();
                            if (confirmResult !== 'confirm') {
                                return [2 /*return*/, Promise.reject()];
                            }
                            _a.label = 3;
                        case 3:
                            this.uploading = true;
                            return [2 /*return*/, true];
                    }
                });
            });
        },
        preFetchS3SignedUrl: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, err, res, url, fields;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, common_utils_1.default.awaitWrap(data_setting_apis_1.default.generateUploadSignedUrl(null, {
                                input: {
                                    fileType: 'CSV',
                                    fileExtensionName: 'CSV',
                                    profileId: this.profileInfo.sid
                                }
                            }))];
                        case 1:
                            _a = _b.sent(), err = _a[0], res = _a[1];
                            if (!err) {
                                url = res.url, fields = res.fields;
                                this.uploadUrl = url;
                                this.extraData = fields;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchUserProperties: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, err, res;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, common_utils_1.default.awaitWrap(data_setting_apis_1.default.getUserProperties(null, {
                                where: {
                                    sid: this.profileInfo.sid
                                }
                            }))];
                        case 1:
                            _a = _b.sent(), err = _a[0], res = _a[1];
                            if (err) {
                                this.currentRouteIsMe && console.log('get user properties failed.');
                            }
                            else {
                                this.tableBody = res.map(function (event) {
                                    event.rowEditing = false;
                                    Object.keys(event).forEach(function (key) {
                                        var eventInfo = _this.tableHeader.find(function (item) { return item.code === key; });
                                        if (eventInfo) {
                                            event[eventInfo.sortCode] = _this.getCellVal(key, event[key]);
                                        }
                                    });
                                    // 备份可编辑项数据
                                    Object.keys(event).forEach(function (key) {
                                        var canEdit = _this.canEditList.some(function (item) { return item.code === key; });
                                        canEdit && (event["".concat(key).concat(_this.joinString)] = event[key]);
                                    });
                                    return event;
                                });
                            }
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateUserProperty: function (_a) {
            var id = _a.id, name = _a.name, type = _a.type, displayName = _a.displayName, source = _a.source, description = _a.description;
            return __awaiter(this, void 0, void 0, function () {
                var _b, err, res;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, common_utils_1.default.awaitWrap(data_setting_apis_1.default.updateUserProperty(null, {
                                data: {
                                    name: name,
                                    displayName: displayName,
                                    source: source,
                                    type: type,
                                    description: description
                                },
                                where: {
                                    name: name,
                                    sid: this.profileInfo.sid,
                                    source: source
                                }
                            }))];
                        case 1:
                            _b = _c.sent(), err = _b[0], res = _b[1];
                            err && this.currentRouteIsMe && this.$message.error(this.$t('error.update_user_property'));
                            return [2 /*return*/];
                    }
                });
            });
        },
        getCellVal: function (code, value) {
            var _this = this;
            if (code === 'type') {
                var info = this.typeOptions.find(function (item) { return item.code === value.toLocaleUpperCase(); });
                return (info && info.name) || value;
            }
            else if (code === 'source') {
                return (value || [])
                    .map(function (i) {
                    var sourceInfo = _this.sourceOptions.find(function (item) { return item.code === i; });
                    return (sourceInfo && sourceInfo.name) || i;
                })
                    .join();
            }
            return value;
        }
    },
    components: {
        SettingsTable: table_vue_1.default
    }
};
